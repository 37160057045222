import React from "react";

import muiTheme from "_common/theme";

interface TextFieldStyleProps {
  floatingLabelFixed?: boolean;
  floatingLabelShrinkStyle?: React.CSSProperties;
  floatingLabelStyle?: React.CSSProperties;
  hintStyle?: React.CSSProperties;
  disabled?: boolean;
}

export const getTextFieldStyleProps = (props: TextFieldStyleProps) => ({
  floatingLabelFixed:
    props.floatingLabelFixed === undefined || props.floatingLabelFixed,
  floatingLabelShrinkStyle: {
    fontWeight: 500,
    ...props.floatingLabelShrinkStyle,
  },
  floatingLabelStyle: {
    fontWeight: 400,
    ...props.floatingLabelStyle,
  },
  hintStyle: {
    fontWeight: 400,
    ...(props.disabled ? { color: muiTheme.textField?.disabledTextColor } : {}),
    ...props.hintStyle,
  },
});
