import React from "react";
import { gettext } from "django-i18n";
import { isEmpty, toPairs } from "lodash";

import CancelIcon from "@deprecated/material-ui/svg-icons/navigation/cancel";
import ArrowForwardIcon from "@deprecated/material-ui/svg-icons/action/trending-flat";
import muiThemeable from "@deprecated/material-ui/styles/muiThemeable";
import { IconButton } from "@deprecated/material-ui";
import AutoComplete from "./auto-complete";
import SelectField from "./SelectField";
import TextField from "./TextField";

/**
 * A field that enables user to edit a JSON dictionary
 */
class DynamicObjectField extends React.Component {
  constructor() {
    super();

    this.state = {
      disableAdd: false,
      fields: [],
      localErrorText: undefined,
      value: {},
    };
  }

  componentDidMount() {
    const { value, defaultValues } = this.props;

    if (defaultValues) {
      this.setState({
        fields: defaultValues,
      });
    }

    if (!value) {
      return;
    }

    const obj = this.deserialize(value);

    if (obj) {
      this.setState({
        fields: toPairs(obj),
        value: obj,
      });
    }
  }

  deserialize(value) {
    const { deserialize, serialize } = this.props;

    if (!serialize) {
      return value;
    }

    let result;

    try {
      result = JSON.parse(value);
    } catch (e) {
      result = {};
    }

    if (deserialize) {
      return deserialize(result);
    }

    return result;
  }

  serialize(value) {
    const { serialize } = this.props;

    if (!serialize) {
      return value;
    }

    const result = typeof serialize === "function" ? serialize(value) : value;
    return result ? JSON.stringify(result) : "";
  }

  validateMappingFieldLength(key, value, maxLength) {
    if (
      (value.trim() && value.trim().length > maxLength) ||
      (key.trim() && key.trim().length > maxLength)
    ) {
      return `Fields may not be greater than ${maxLength} characters`;
    }

    if (
      (!key.trim() && key) ||
      (value && !value.trim()) ||
      (!key.trim() && value) ||
      (!value.trim() && key)
    ) {
      return "Fields cannot be blank.";
    }

    return null;
  }

  updateFields(fields) {
    const { maxLength, onChange } = this.props;

    const trimmedFields = fields.map((field) =>
      field.map((element) => element.trim())
    );

    const value = trimmedFields
      .filter(([key, val]) => key && val)
      .reduce((acc, [key, val]) => {
        acc[key] = val;
        return acc;
      }, {});

    const disableAdd = trimmedFields.some((field) => !field[0] || !field[1]);

    const currentRow = fields[fields.length - 1];
    const prevRows = fields.slice(0, fields.length - 1);

    let localErrorText;

    if (currentRow) {
      if (
        prevRows.length &&
        prevRows.some((field) => !field[0].trim() || !field[1].trim())
      ) {
        localErrorText = "Fields cannot be blank.";
      }

      localErrorText = this.validateMappingFieldLength(
        currentRow[0],
        currentRow[1],
        maxLength
      );
    }

    this.setState({
      disableAdd,
      fields, // Don't use trimmedFields; inputs with spaces will not work
      localErrorText,
      value,
    });

    if (JSON.stringify(value) !== JSON.stringify(this.state.value)) {
      const serializedValue = this.serialize(value);
      onChange && onChange(serializedValue);
    }
  }

  handleAdd() {
    const fields = [...this.state.fields, ["", ""]];
    this.updateFields(fields);
  }

  handleRemove(index) {
    const { disableAdd } = this.state;
    const fields = [...this.state.fields];
    fields.splice(index, 1);
    this.updateFields(fields);

    if (isEmpty(fields) || disableAdd) {
      this.setState({ localErrorText: undefined });
    }
  }

  handleKeyChange(index, key) {
    const fields = [...this.state.fields];
    fields[index][0] = key;
    this.updateFields(fields);
  }

  handleValueChange(index, value) {
    const fields = [...this.state.fields];
    fields[index][1] = value;
    this.updateFields(fields);
  }

  handleKeyDown(e) {
    const { disableAdd } = this.state;

    if (disableAdd) {
      return;
    }

    if (e.key === "Enter") {
      this.handleAdd();
    }
  }

  handleKeyPress(e, fieldType, isMultiline) {
    const { disableAdd } = this.state;

    if (!disableAdd && e.shiftKey && e.which === 13) {
      // Allow new pair mapping to be inserted
      e.preventDefault();
      if (fieldType.includes("value")) {
        this.handleAdd();
      }
    } else if (e.which === 13 && !isMultiline) {
      // Prevent form submission, allow new line to be entered for multiline
      e.preventDefault();
    }
  }

  addFloatingLabelStyles(styles) {
    return {
      inputStyle: {
        height: "68px",
        marginTop: 0,
        top: 0,
        ...styles.inputStyle,
      },
      floatingLabelStyle: {
        top: "24px",
        ...styles.floatingLabelStyle,
      },
      style: {
        height: "58px",
        ...styles.style,
      },
    };
  }

  renderKeyField(keyValue, value, rowIndex) {
    const {
      keyHintTextMapping,
      keyOptions,
      autoComplete,
      isOrientationVertical,
    } = this.props;

    const key = `key_${rowIndex}`;

    const fieldHint =
      keyHintTextMapping[value] || keyHintTextMapping.__default__;

    let styles = {
      style: { float: "left", height: "58px", width: "45%" },
    };

    styles = this.addFloatingLabelStyles(styles);

    if (isOrientationVertical) {
      styles.style.width = "100%";
    }

    if (keyOptions && autoComplete) {
      styles.style.marginTop = "-14px"; // Aligns <AutoComplete/> since it's
      // taller than corresponding <TextField/>
      styles.style.zIndex = "1"; // Prevents blocking of addLabel

      return (
        // AutoComplete will show floating text for the first row only. They serve as
        // column headings. On subsequent rows, show hint text only on a blank field;
        // to hide floating text for these subsequent rows, use a space so that the
        // component's vertical spacing is maintained.
        <AutoComplete
          autoFocus={!keyValue}
          floatingLabelText={rowIndex === 0 || !keyValue ? fieldHint : " "}
          maxOptions={9}
          options={keyOptions}
          onChange={(value) => this.handleKeyChange(rowIndex, value)}
          value={keyValue}
          {...styles}
        />
      );
    }

    let floatingLabelText = keyHintTextMapping.__default__;
    if (!isOrientationVertical && rowIndex !== 0) {
      floatingLabelText = "";
    }

    return (
      // TextField will show floating text for the first row only. They serve as
      // column headings. On subsequent rows, show hint text only on a blank field;
      // Unlike AutoComplete, the an empty floating label here will not affect vertical spacing.
      <TextField
        autoFocus={!keyValue}
        key={key}
        floatingLabelText={floatingLabelText}
        hintText={fieldHint}
        data-cy="dynamic-object-text-field"
        onChange={(event) => this.handleKeyChange(rowIndex, event.target.value)}
        onKeyPress={(event) => this.handleKeyPress(event, key, false)}
        value={keyValue}
        {...styles}
      />
    );
  }

  renderValueField(value, keyValue, rowIndex) {
    const {
      valueHintTextMapping,
      valueMultiline,
      valueOptions,
      autoComplete,
      isOrientationVertical,
    } = this.props;

    let styles = {
      style: { float: "right", height: "58px", width: "45%" },
    };

    if (isOrientationVertical) {
      styles = {
        style: { width: "100%", position: "relative", bottom: "10px" },
      };
    } else {
      styles = this.addFloatingLabelStyles(styles);
    }

    const key = `value_${rowIndex}`;

    const fieldHint =
      valueHintTextMapping[keyValue] || valueHintTextMapping.__default__;

    if (valueOptions && autoComplete) {
      // Aligns <AutoComplete/> since it's taller than corresponding <TextField/>
      styles.style.marginTop = "-14px";

      return (
        // AutoComplete will show floating text for the first row only. They serve as
        // column headings. On subsequent rows, show hint text only on a blank field;
        // to hide floating text for these subsequent rows, use a space so that the
        // component's vertical spacing is maintained.
        <AutoComplete
          floatingLabelText={rowIndex === 0 || !value ? fieldHint : " "}
          maxOptions={9}
          options={valueOptions}
          onChange={(value) => this.handleValueChange(rowIndex, value)}
          value={value}
          {...styles}
        />
      );
    }

    if (valueOptions) {
      return (
        // SelectField will show floating text for the first row only. They serve as
        // column headings. On subsequent rows, show hint text only on a blank field;
        // to hide floating text for these subsequent rows, use a space so that the
        // component's vertical spacing is maintained.
        <SelectField
          key={key}
          floatingLabelText={rowIndex === 0 ? fieldHint : " "}
          maxHeight={192}
          onChange={(event, index, value) =>
            this.handleValueChange(rowIndex, value)
          }
          value={value}
          {...styles}
        >
          {valueOptions}
        </SelectField>
      );
    }

    let floatingLabelText = valueHintTextMapping.__default__;
    if (!isOrientationVertical && rowIndex !== 0) {
      floatingLabelText = "";
    }

    return (
      // TextField will show floating text for the first row only. They serve as
      // column headings. On subsequent rows, show hint text only on a blank field;
      // Unlike AutoComplete, the an empty floating label here will not affect vertical spacing.
      <TextField
        key={key}
        floatingLabelText={floatingLabelText}
        hintText={fieldHint}
        data-cy="dynamic-object-text-field"
        onChange={(event) =>
          this.handleValueChange(rowIndex, event.target.value)
        }
        onKeyPress={(event) => this.handleKeyPress(event, key, valueMultiline)}
        value={value}
        multiLine={valueMultiline}
        rowsMax={valueMultiline ? 5 : 1}
        {...styles}
      />
    );
  }

  renderRowVertical(key, value, rowIndex) {
    const { maxLength, label } = this.props;

    const deleteButton = (
      <CancelIcon
        hoverColor="rgba(0,0,0,0.87)"
        onClick={() => this.handleRemove(rowIndex)}
      />
    );

    const deleteStyle = {
      position: "relative",
      left: "16px",
      color: "rgba(0,0,0,0.6)",
    };

    const arrowStyle = {
      position: "relative",
      top: "8px",
      left: "255px",
      transform: "rotate(90deg)",
    };

    const labelStyle = {
      flexBasis: "100%",
      width: "1px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    };

    const arrowIcon = <ArrowForwardIcon style={arrowStyle} />;
    const localErrorText = this.validateMappingFieldLength(
      key,
      value,
      maxLength
    );

    const container = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "nowrap",
      marginBottom: localErrorText ? "-3px" : "0px",
    };

    return (
      <>
        <tr>
          <td>
            <div style={{ position: "relative", paddingBottom: "8px" }}>
              <div style={container}>
                <div style={labelStyle}>{`${label} ${rowIndex + 1}`}</div>
                <IconButton style={deleteStyle}>{deleteButton}</IconButton>
              </div>
              {localErrorText && (
                <>
                  <br style={{ display: "none" }} />
                  <span
                    style={{
                      color: "#f44336",
                      position: "relative",
                      top: "-12px",
                    }}
                  >
                    {localErrorText}
                  </span>
                </>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td>{this.renderKeyField(key, value, rowIndex)}</td>
        </tr>
        <tr>
          <td>{arrowIcon}</td>
        </tr>
        <tr>
          <td>{this.renderValueField(value, key, rowIndex)}</td>
        </tr>
      </>
    );
  }

  renderRow(key, value, rowIndex) {
    const { isOrientationVertical } = this.props;

    const deleteButton = (
      <CancelIcon
        className="sde-member-remove"
        data-cy="remove-member"
        onClick={() => this.handleRemove(rowIndex)}
      />
    );

    if (isOrientationVertical) {
      return this.renderRowVertical(key, value, rowIndex);
    }

    const arrowStyle = {
      position: "relative",
      top: "25px",
      left: "15px",
    };

    const arrowIcon = <ArrowForwardIcon style={arrowStyle} />;

    const deleteStyle = {
      paddingTop: "22px",
      verticalAlign: "top",
    };

    return (
      <tr key={`row_${rowIndex}`}>
        <td>
          {this.renderKeyField(key, value, rowIndex)}
          {arrowIcon}
          {this.renderValueField(value, key, rowIndex)}
        </td>
        <td style={deleteStyle}>{deleteButton}</td>
      </tr>
    );
  }

  render() {
    const { addLabel, errorText, isOrientationVertical, muiTheme } = this.props;
    const { disableAdd, fields, localErrorText } = this.state;
    let buttonStyle = {
      position: "relative",
      zIndex: "2",
    };
    if (disableAdd) {
      buttonStyle = {
        ...buttonStyle,
        pointerEvents: "none",
        // Disabled link color
        color: muiTheme.textField.disabledTextColor,
      };
    }

    const newLabelButton = (
      <a
        onClick={this.handleAdd.bind(this)}
        onKeyDown={this.handleKeyDown.bind(this)}
        tabIndex="0"
        style={buttonStyle}
      >
        {addLabel}
      </a>
    );

    if (isOrientationVertical) {
      const multiLineStyles = {
        padding: "16px 20px 20px 20px",
        background: "#f2f2f2",
        marginBottom: 12,
        borderRadius: 3,
      };
      return (
        <>
          {fields.map((field, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`row_${index}`} style={multiLineStyles}>
              <table style={{ width: "100%" }}>
                <tbody>{this.renderRow(field[0], field[1], index)}</tbody>
              </table>
            </div>
          ))}
          <div style={{ paddingLeft: "8px" }}>{newLabelButton}</div>
        </>
      );
    }

    return (
      <div style={{ paddingLeft: "8px" }}>
        {errorText && <span style={{ color: "#f44336" }}>{errorText}</span>}
        {localErrorText && (
          <span style={{ color: "#f44336" }}>{localErrorText}</span>
        )}
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th />
              <th style={{ width: "25px" }} />
            </tr>
          </thead>

          <tbody>
            {fields.map((field, index) =>
              this.renderRow(field[0], field[1], index)
            )}
          </tbody>
        </table>
        {newLabelButton}
      </div>
    );
  }
}

DynamicObjectField.defaultProps = {
  addLabel: gettext("Add"),
  keyHintText: gettext("Key"),
  serialize: false,
  valueHintText: gettext("Value"),
};

export default muiThemeable()(DynamicObjectField);
