import { gettext } from "django-i18n";
import { isEmpty } from "lodash";
import React from "react";

import IconButton from "@deprecated/material-ui/IconButton/IconButton";
import FileIcon from "@deprecated/material-ui/svg-icons/editor/attach-file";
import ClearFileIcon from "@deprecated/material-ui/svg-icons/navigation/cancel";
import LinearProgress from "@deprecated/material-ui/LinearProgress";
import Tooltipify from "./tooltipify";
import WorkerScript from "../utils/fileReaderWorker";

/**
 * Text field that gets its value from an uploaded file.
 */
class FileUploadTextField extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
    };

    // Setup webworker to handle file parsing on background thread
    this.worker = new Worker(WorkerScript);
    this.worker.onmessage = (e) => {
      this.props.onChange && this.props.onChange(e.data);
      this.setState({ loading: false });
    };
  }

  componentWillUnmount() {
    // Clean up worker
    this.worker.terminate();
  }

  handleFileChange(e) {
    const { files } = e.target;

    if (isEmpty(files)) {
      return;
    }

    this.setState({ loading: true });
    this.worker.postMessage(files[0]);
  }

  handleClear = () => {
    const { onChange } = this.props;

    this.fileInput.value = null;
    onChange && onChange(null);
  };

  render() {
    const { errorText, hintText, textWidth, value, id } = this.props;

    const rectStyle = {
      height: "36px",
      borderRadius: "2px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      padding: "6px 6px",
    };
    const textStyle = {
      display: "inline-block",
      verticalAlign: "top",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: textWidth,
      cursor: "pointer",
    };
    const iconStyle = {
      width: 24,
      height: 24,
    };
    const buttonStyle = {
      float: "right",
      width: 24,
      height: 24,
      padding: 0,
    };
    const errorStyle = {
      color: "rgb(244, 67, 54)",
      fontSize: 12,
      lineHeight: "12px",
      margin: "8px 0px 16px",
    };
    const clearButtonTooltip =
      (!value && gettext("No file to remove")) || gettext("Remove File");

    return (
      <div>
        <div style={rectStyle}>
          <div style={textStyle} onClick={() => this.fileInput.click()}>
            {(value && value.substring(0, 200)) || hintText}
          </div>

          <Tooltipify message={clearButtonTooltip}>
            <div style={buttonStyle}>
              <IconButton
                iconStyle={iconStyle}
                style={buttonStyle}
                className="sde-icon-button"
                onClick={this.handleClear}
                disabled={!value}
              >
                <ClearFileIcon />
              </IconButton>
            </div>
          </Tooltipify>

          <Tooltipify message={gettext("Change File")}>
            <IconButton
              iconStyle={iconStyle}
              style={buttonStyle}
              className="sde-icon-button"
              onClick={() => this.fileInput.click()}
            >
              <FileIcon />
            </IconButton>
          </Tooltipify>

          <input
            style={{ display: "none" }}
            ref={(fileInput) => {
              this.fileInput = fileInput;
            }}
            id={id}
            type="file"
            onChange={this.handleFileChange.bind(this)}
          />
        </div>

        {this.state.loading && <LinearProgress mode="indeterminate" />}

        <div style={errorStyle}>{errorText}</div>
      </div>
    );
  }
}

FileUploadTextField.defaultProps = {
  errorText: null,
  hintText: gettext("Browse ..."),
  textWidth: 475,
};

export default FileUploadTextField;
