import { gettext } from "django-i18n";
import React from "react";
import EventListener from "react-event-listener";
import cookie from "js-cookie";
import PropTypes from "prop-types";

import FlatButton from "../buttons/flat";
import Paper, { Header } from "../paper";
import Loader from "../loader";

/**
 * Function which will auto-scroll to field with validation error
 * Pass to <Formik> as onSubmitFail
 */
export const scrollToErrorOnSubmitFail = ({ errors }) => {
  if (!errors) {
    return;
  }

  function getFieldName(obj) {
    const key = Object.keys(obj)[0];
    const value = obj[key];

    if (typeof value === "string") {
      return key;
    }

    return `${key}.${getFieldName(value)}`;
  }

  const fieldName = getFieldName(errors);
  const field = document.querySelector(
    `*[name="${fieldName}"],*[data-error="${fieldName}"]`
  );
  field && field.focus();
};

/**
 * Paper-based component used to hold forms. Can be used nicely in a dialog.
 */
class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxHeight: null,
      contentMaxHeight: null,
    };

    this.containerRef = React.createRef();
    this.footerRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => this.setHeight(), 0);
  }

  handleResize = () => {
    this.setHeight();
  };

  setHeight() {
    const node = this.containerRef.current;
    const parent = node && node.parentElement;

    // A named parent might have styling we do not know what to do with
    if (!parent || parent.className) {
      return;
    }

    const footerNode = this.footerRef.current;

    setTimeout(() => {
      const maxHeight = window
        .getComputedStyle(parent)
        .getPropertyValue("max-height");
      const footerHeight = footerNode.clientHeight;

      // we need to keep room for the footer by limiting the content
      this.setState({
        maxHeight,
        contentMaxHeight: `${Math.floor(
          parseInt(maxHeight, 10) - parseInt(footerHeight, 10)
        )}px`,
      });

      // prevent scrollbars from showing up
      parent.style.overflow = "hidden";
    }, 0);
  }

  renderContents() {
    const {
      children,
      headerActions,
      headerProps,
      headerStyle,
      hideHeader,
      title,
      titleAside,
      content,
      secondary,
      subtitle,
      subtitleComponent,
      contentStyle,
      primaryStyle,
      secondaryStyle,
    } = this.props;

    const { contentMaxHeight } = this.state;

    let contents = (
      <div style={{ overflow: "auto", maxHeight: contentMaxHeight }}>
        {!hideHeader && (
          <Header
            actions={headerActions}
            inlineStyle={headerStyle}
            title={title}
            titleAside={titleAside}
            subtitle={subtitle}
            subtitleComponent={subtitleComponent}
            {...headerProps}
          />
        )}
        <section className="sde-paper-content" style={contentStyle}>
          {children}
        </section>
      </div>
    );
    if (content) {
      contents = content;
    }

    if (secondary) {
      contents = (
        <div className="sde-paper-container">
          <div
            className="sde-paper-container-column"
            style={{ maxHeight: contentMaxHeight, ...primaryStyle }}
          >
            {contents}
          </div>
          <div
            className="sde-paper-container-column is-alt"
            style={{ maxHeight: contentMaxHeight, ...secondaryStyle }}
          >
            <section className="sde-paper-content">{secondary}</section>
          </div>
        </div>
      );
    }

    return contents;
  }

  renderFooterActions() {
    const {
      isLoading,
      onBack,
      onSubmit,
      onCancel,
      backLabel,
      backButtonPrimary,
      submitWarning,
      submitLabel,
      submitTitle,
      disableBack,
      disableCancel,
      disableSubmit,
      cancelLabel,
      cancelWarning,
      cyData,
      renderSubmit,
      renderCancel,
      renderBack,
      renderButtons,
    } = this.props;

    const backButton = (
      <FlatButton
        data-cy="back-button"
        className="sde-form-onback"
        label={backLabel}
        onClick={onBack}
        primary={backButtonPrimary}
        disabled={isLoading || disableBack}
        tabIndex={-1}
      />
    );

    const submitButton = (
      <FlatButton
        data-cy={cyData.submitButtonId || "submit-button"}
        type="submit"
        primary
        warning={submitWarning}
        label={submitLabel}
        onClick={onSubmit}
        title={submitTitle}
        disabled={isLoading || disableSubmit}
      />
    );

    const cancelButton = (
      <FlatButton
        label={cancelLabel}
        warning={cancelWarning}
        disabled={disableCancel}
        onClick={onCancel}
        tabIndex={-1}
      />
    );

    const backAction = renderBack
      ? renderBack(backButton, this.props)
      : backButton;

    const submitAction = renderSubmit
      ? renderSubmit(submitButton, this.props)
      : submitButton;

    const cancelAction = renderCancel
      ? renderCancel(cancelButton, this.props)
      : cancelButton;

    const defaultOrder = (
      <div className="sde-paper-footer-actions">
        {onBack && backAction}
        {onSubmit && submitAction}
        {onCancel && cancelAction}
      </div>
    );

    return renderButtons ? (
      <div className="sde-paper-footer-actions">
        {renderButtons(
          backButton,
          submitButton,
          cancelButton,
          disableSubmit,
          onSubmit
        )}
      </div>
    ) : (
      defaultOrder
    );
  }

  render() {
    let csrfToken;

    const {
      action,
      cyData,
      formRef,
      encType,
      method,
      target,
      isLoading,
      secondary,
    } = this.props;

    const { maxHeight } = this.state;

    const formProps = {
      ref: formRef,
      method,
      action,
      target,
      encType,
      "data-cy": cyData.formId,
    };

    if (target === "_blank") {
      formProps.rel = "noopener noreferrer";
    }

    if (method || action) {
      csrfToken = (
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={cookie.get("sde-csrftoken")}
        />
      );
    }

    const width = this.props.width || (secondary ? "930px" : "640px");

    return (
      <Paper
        className="sde-form"
        width={width}
        ref={this.containerRef}
        style={{ maxHeight }}
      >
        <form autoComplete="off" {...formProps}>
          {csrfToken}
          {this.renderContents()}
          <footer className="sde-paper-footer" ref={this.footerRef}>
            <Loader loading={isLoading} />
            {this.renderFooterActions()}
          </footer>
        </form>
        <EventListener target="window" onResize={this.handleResize} />
      </Paper>
    );
  }
}

Form.propTypes = {
  secondary: PropTypes.node,
  content: PropTypes.node,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  renderSubmit: PropTypes.func,
  renderCancel: PropTypes.func,
  renderBack: PropTypes.func,
};

Form.defaultProps = {
  backLabel: gettext("Previous"),
  cancelLabel: gettext("Cancel"),
  cyData: {},
  submitLabel: gettext("Done"),
};

export default Form;
